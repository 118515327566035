<template>
  <div class="march-main-container" :class='$mq'>
    <!-- MAIN SCREEN -->
    <div class="march-main-screen" :class='$mq'>
      <div class="march-main-screen-h1" :class='$mq'>
        <h1 :class='$mq'>Создай свою неповторимую шарнирную<br>
          <span class='big'>куклу</span><br>
          <span class='script'>за 2 месяца</span>
        </h1>

        <KRoundedButton class='march-main-button' :class='$mq' text-color="#fff" color="#d76666"
                        v-scroll-to="'#who'">
          Создать куклу
          <template v-slot:icon>
            <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
          </template>
        </KRoundedButton>
      </div>

      <div class="march-main-img-block" :class='$mq'>
        <div class="march-main-img" :class='$mq'>
          <KImage :src="require('@/assets/bjd/bjd.png')" alt="" width="100%"/>
        </div>
      </div>

      <div class="march-main-video-cont screen" :class='$mq'>
        <div class="march-main-video" :class='$mq' @click="playVideo('0K27QtULg4Q')">
          <KImage class="march-main-video-icon" :src="require('@/assets/bjd/video.png')" alt=""
                  width="60%"/>
          <p>Посмотреть, как создается кукла</p>
        </div>
      </div>

      <div class="march-main-images petals" :class='$mq'>
        <KImage :src="require('@/assets/bjd/petals.png')" alt="" width="100%"/>
      </div>
      <div class="march-main-images top-left" :class='$mq'>
        <KImage :src="require('@/assets/bjd/top-left.png')" alt="" width="100%"/>
      </div>
      <div class="march-main-images top-right" :class='$mq'>
        <KImage :src="require('@/assets/bjd/top-right.png')" alt="" width="100%"/>
      </div>
      <div class="march-main-images light-left" :class='$mq'></div>
      <div class="march-main-images light-right" :class='$mq'></div>
    </div>
    <KRoundedButton class='march-main-button phone' :class='$mq' text-color="#fff" color="#d54d59"
                    v-scroll-to="'#product'">
      Создать куклу
      <template v-slot:icon>
        <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
      </template>
    </KRoundedButton>

    <!-- ADVANTAGES -->
    <div class="advantages-container" :class='$mq'>
      <div class="advantages-border" :class='$mq'></div>
      <div class="advantages" :class='$mq'>
        <p class='adv-number' :class='$mq'>255+</p>
        <p>учеников научились создавать кукол</p>
      </div>

      <div class="advantages" :class='$mq'>
        <p class='adv-number' :class='$mq'>381</p>
        <p>страницы, которые подробно опишут весь процесс </p>
      </div>

      <div class="advantages" :class='$mq'>
        <p class='adv-number' :class='$mq'>15ч</p>
        <p>видеоуроков наглядно покажут, что сделать )</p>
      </div>
    </div>

    <!-- VK -->
    <div class="product-vk" :class='$mq' id="who">
      <div class="product-vk-text" :class='$mq'>
        <h3>+ Доступ закрытую группу Вконтакте</h3>
        <p>где вы сможете делиться своими результатами, задавать вопросы и получать обратную связь от
          меня. </p>
      </div>

      <div class="product-vk-img-block" :class='$mq'>
        <div class="product-vk-img" :class='$mq'>
          <KImage :src="require('@/assets/march/pr-vk.png')" alt="" width="100%"/>
        </div>
      </div>
    </div>

    <!-- WHO FOR -->
    <div class="who-block" :class='$mq'>
      <div class="who-text" :class='$mq'>
        <h2 class="bottom" :class='$mq'>Для кого этот курс?</h2>
        <p>Курс <b>не для новичков</b>, он рассчитан на людей, имеющих опыт в работе с кукольным пластиком. Это
          трудоемкий и долгий процесс, не ждите быстрых достижений всему свое время, но результат того стоит :)</p>
        <router-link to="/demo/bjd">Посмотреть 1-ую главу</router-link>
      </div>

      <div class="who-img-block" :class='$mq'>
        <div class="who-img">
          <KImage :src="require('@/assets/bjd/bjd2.jpg')" alt="" width="100%"/>
        </div>
      </div>
    </div>

    <!-- BUY -->
    <div class="buy" :class='$mq'>
      <a @click="tryToBuy(24)" href="#">Купить в рассрочку</a>

      <div class="buy-text" :class='$mq'>
        <p>за 17500&#8381;</p>
        <!--              <p class="old">17500&#8381;</p>-->
      </div>
      <div class="buy-button" :class='$mq'>
        <KRoundedButton @click="tryToBuy(22)" class="button-buy" :class='$mq' text-color="#fff" color="#d76666"
                        v-scroll-to="'#second'">
          Купить курс
        </KRoundedButton>
        <div class="buy-button-bg" :class='$mq'>
          <KImage :src="require('@/assets/march/all-bt.png')" alt="" width="100%"/>
        </div>
      </div>

      <div class="buy-bee" :class='$mq'>
        <KImage :src="require('@/assets/march/main-bee.png')" alt="" width="100%"/>
      </div>
    </div>

    <!-- REVIEWS -->
    <div class="reviews" :class='$mq'>
      <h2 class="center" :class='$mq'>Работы девочек <span></span></h2>

      <div class="review-dark left" :class='$mq'></div>
      <splide :slides="items" :options="options">
        <splide-slide v-for="(slide, index) in items" :key="index">
          <KImage :src="slide" @click="showImage(slide)" width="100%"></KImage>
        </splide-slide>
      </splide>
      <div class="review-dark right" :class='$mq'></div>
    </div>

    <!-- ADD -->
    <div class="add" :class='$mq'>
      <h2 class="center" :class='$mq'>Этот курс отлично дополнит:</h2>
      <MainItem :items="[4,5]"/>
    </div>

    <!-- BUY ALL -->
    <div class="buy-all" :class='$mq'>
      <div class="buy-all-text" :class='$mq'>
        <p>Курс по шарнирке</p>
        <p>+</p>
        <p>Анатомия головы</p>
        <p>+</p>
        <p>Анатомия тела</p>
        <p>=</p>
        <div class="buy-all-price" :class='$mq'>
          <p class="new">22500&#8381;</p>
          <!--                    <p class="old">22500&#8381;</p>-->
        </div>
      </div>

      <div class="buy-all-button" :class='$mq'>
        <KRoundedButton @click="tryToBuy(23)" class="button-buy all" :class='$mq' text-color="#fff" color="#d76666"
                        v-scroll-to="'#second'">
          Купить ВСЁ пакетом
        </KRoundedButton>
        <div class="buy-button-bg" :class='$mq'>
          <KImage :src="require('@/assets/march/all-bt.png')" alt="" width="100%"/>
        </div>
      </div>

      <div class="buy-bee" :class='$mq'>
        <KImage :src="require('@/assets/march/main-bee.png')" alt="" width="100%"/>
      </div>
    </div>

    <a @click="tryToBuy(25)" class='buy-parts' href="#">Купить в рассрочку</a>


    <!-- FAQ -->
    <div class="faq" :class='$mq'>
      <h2 class="center faq" :class='$mq'>Часто задаваемые вопросы</h2>
      <accordion>
        <accordion-panel>
          <accordion-panel-header>Где мне будут доступны материалы? На какой платформе?
          </accordion-panel-header>
          <accordion-panel-content>Все материалы будут доступны на этом сайте <a
            href="https://kibanovadolls.ru/mycourses">в вашем личном кабинете.</a>
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Книга только электронная или есть бумажная версия?</accordion-panel-header>
          <accordion-panel-content>Бумажной версии, к сожалению нет. Книга в только электронном видео.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Это подходит для начинающих?</accordion-panel-header>
          <accordion-panel-content>Нет, только для мастеров, имеющих опыт в лепке кукол.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Как долго я смогу изучать материал? Есть ли ограничения по времени?
          </accordion-panel-header>
          <accordion-panel-content>Материал доступен 1 год. После вы сможете продлить доступ по вашему
            желанию.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Смогу ли я изучать курс в удобное для меня время?</accordion-panel-header>
          <accordion-panel-content>Конечно, когда вам удобно, на любом устройстве.</accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Я получу доступ сразу после оплаты?</accordion-panel-header>
          <accordion-panel-content>Да, вам на почту придет письмо с дальнейшими инструкциями.
          </accordion-panel-content>
        </accordion-panel>
        <accordion-panel>
          <accordion-panel-header>Какие материалы нужны для создания куклы?</accordion-panel-header>
          <accordion-panel-content><a target="_blank" href="/mycourses/bjd-materials">Здесь можно посмотреть подробный
            список</a> всех материалов (чтобы его увидеть
            нужно авторизоваться)
          </accordion-panel-content>
        </accordion-panel>
      </accordion>
    </div>

  </div>
</template>

<script>
import KImage from "../../Widgets/KImage";
import KRoundedButton from "../../Widgets/KRoundedButton";
import KIHandFlower from "../../Widgets/Icons/KIHandFlower";
import MainItem from '../../MainPage/MainItem.vue'
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


import image1 from "@/assets/newyear/reviews/Bjd1.jpg"
import image2 from "@/assets/newyear/reviews/Bjd2.jpg"
import image3 from "@/assets/newyear/reviews/Bjd3.jpg"
import image4 from "@/assets/newyear/reviews/Bjd4.jpg"
import image5 from "@/assets/newyear/reviews/Bjd5.jpg"
import image6 from "@/assets/newyear/reviews/Bjd6.jpg"

export default {
  name: "MarchBook",
  components: {
    KImage,
    KRoundedButton,
    KIHandFlower,
    Splide,
    SplideSlide,
    MainItem
  },
  data() {
    return {
      items: [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
      ],
      options: {
        rewind: true,
        perPage: 4,
        gap: '0.3rem',
        pagination: false,
        // cover: true,
        breakpoints: {
          450: {
            perPage: 1,
          },
          700: {
            perPage: 2,
          },
          1000: {
            perPage: 3,
          },
        },
      },
    }
  },
  methods: {
    tryToBuy(id) {
      this.$router.push("/item/" + id)
    },
    showImage(src) {
      this.$swal.fire({
        // width: '80%',
        height: '90%',
        background: '#eae2e0',
        html: '<img width="100%" src="' + src + '"/>',
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: "",
      })
    },
    playVideo(videoId) {
      let videoWidth = window.innerWidth < 800 ? window.innerWidth : 800
      let videoHeight = videoWidth / 1.77
      this.$swal.fire({
        width: videoWidth + 'px',
        background: '#eae2e0',
        html: "<iframe frameborder=\"0\" allowfullscreen=\"1\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" title=\"YouTube video player\" width=\"" + videoWidth + "\" height=\"" + videoHeight + "\" src=\"https://www.youtube.com/embed/" + videoId + "?autoplay=1&controls=0&rel=0&modestbranding=0&loop=1&enablejsapi=1&origin=https%3A%2F%2Fkibanovadolls.ru&widgetid=1\"></iframe>",
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: ""
      })
    }
  }
}
</script>

<style scoped>


.march-main-container {
  max-width: 920px;
  margin: 0 auto;
  padding: 0 2em;
}

.march-main-container.halftablet,
.march-main-container.mobile {
  padding: 0 1em;
}

.march-main-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: -8em;
  margin-top: -6em;
}

.march-main-screen.halftablet {
  margin-top: -4em;
  justify-content: space-around;
  margin-bottom: -6em;
}

.march-main-screen.mobile {
  margin-top: 0em;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 0em;
}

.march-main-screen-h1 {
  width: 40%;
  position: relative;
  z-index: 10;
  top: -2em;
}

.march-main-screen-h1.halftablet {
  width: 60%;
  max-width: 250px;
}

.march-main-screen-h1.mobile {
  width: 50%;
  max-width: 180px;
  top: 2em;
  margin-bottom: 5em;
}

h1 {
  font-size: 3em;
  line-height: 1.1em;
  position: relative;
  margin-bottom: 0.5em;
  margin-top: 1em;
  z-index: 5;
}

h1.tablet {
  font-size: 2.3em;
}

h1.halftablet {
  font-size: 2em;
}

h1.mobile {
  font-size: 1.4em;
}

span.big {
  font-size: 1.9em;
  line-height: 1.2em;
  text-transform: uppercase;
}

span.script {
  font-family: 'TheArtist', sans-serif;
  font-size: 1.1em;
  position: absolute;
  right: -0.5em;
  top: 90%;
}

h2 {
  font-size: 2.6em;
  line-height: 1em;
  margin-bottom: 0.5em;
  position: relative;
}

h2.bottom {
  margin-bottom: 0.2em;
}

h2.tablet,
h2.halftablet {
  font-size: 2.3em;
}

h2.mobile {
  font-size: 2em;
}

h2 span {
  font-family: 'TheArtist', sans-serif;
  position: absolute;
  bottom: -0.8em;
  right: 15%;
}

h2.tablet span,
h2.halftablet span,
h2.mobile span {
  right: 20%
}

h2.center {
  text-align: center;
}

h2.faq {
  font-size: 3em;
  margin-bottom: 0.5em;
}

h2.faq.mobile {
  font-size: 2em;
}

a {
  color: #023a4d;
  font-weight: 800;
}

a.mobile {
  font-size: 0.7em;
  line-height: 1.3em;
}

a.main {
  color: white;
  font-weight: 600;
  margin-right: 2em;
}

a::after {
  content: "";
  border-top: 1px solid #023a4d;
  position: absolute;
  width: 100%;
  left: 0;
  right: 100%;
  bottom: 0px;
  transition: width 0.4s ease-out;
}

a:hover {
  color: #d76666;
}

a:hover::after {
  width: 0;
}

b {
  font-weight: 800;
}

.march-main-img-block {
  position: relative;
  width: 50%;
  z-index: 1;
}

.march-main-img-block.halftablet,
.march-main-img-block.mobile {
  width: 40%;
  right: 10%;
}

.march-main-img {
  position: relative;
  width: 439px;
}

.march-main-img.tablet {
  width: 400px;
}

.march-main-img.halftablet {
  width: 300px;
}

.march-main-img.mobile {
  position: absolute;
  width: 220px;
  top: -2em;
}

.march-main-video-cont {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.3em;
  right: 0px;
  bottom: 13.5em;
  font-size: 0.8em;
  z-index: 10;
}

.march-main-video-cont.tablet,
.march-main-video-cont.halftablet,
.march-main-video-cont.mobile {
  font-size: 0.7em;
}

.march-main-video-cont.halftablet,
.march-main-video-cont.mobile {
  bottom: 10em;
}

.march-main-video-cont.screen.mobile {
  display: none;
}

.march-main-video-cont.child,
.march-main-video-cont.child.halftablet,
.march-main-video-cont.child.mobile {
  right: 50%;
  transform: translateX(50%);
  bottom: -2em;
  background: transparent;
}

.march-main-video-cont.child.halftablet,
.march-main-video-cont.child.mobile {
  bottom: 0em !important;
}

.march-main-video {
  width: 9em;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
}

.march-main-video.halftablet,
.march-main-video.mobile {
  width: 6em;
}

.march-main-video:hover {
  transform: scale(1.1);
}

.march-main-video p {
  margin: 1em auto 0 auto;
}

.march-main-video.halftablet p,
.march-main-video.mobile p {
  display: none;
}

.march-main-video {
  animation: video-scale 1s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes video-scale {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(1.05);
  }
  75% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

.march-main-images {
  position: absolute;
  z-index: 0;
}

.march-main-images.petals {
  width: 404px;
  right: 5em;
  top: 10em;
  z-index: 2;
}

.march-main-images.petals.tablet,
.march-main-images.petals.halftablet {
  width: 300px;
}

.march-main-images.petals.mobile {
  width: 250px;
  top: 3em;
}

.march-main-images.top-left {
  width: 486px;
  top: -0.5em;
  left: -5em;
  z-index: 1;
}

.march-main-images.top-left.tablet {
  width: 380px;
  top: -0.3em;
}

.march-main-images.top-left.halftablet,
.march-main-images.top-left.mobile {
  display: none;
}

.march-main-images.top-right {
  width: 486px;
  top: -0.5em;
  right: -7em;
  z-index: 1;
}

.march-main-images.top-right.tablet {
  width: 380px;
  top: -2.3em;
}

.march-main-images.top-right.halftablet,
.march-main-images.top-right.mobile {
  width: 250px;
  top: -5.5em;
  left: -2em;
}

.march-main-images.light-left, .march-main-images.light-right {
  width: 1000px;
  height: 1000px;
  background: radial-gradient(circle 500px, #ffffff, transparent 100%);
}

.march-main-images.light-left {
  top: -60%;
  left: -50%;
}

.march-main-images.light-left.tablet {
  top: -600px;
  left: -500px;
}

.march-main-images.light-left.halftablet,
.march-main-images.light-left.mobile {
  width: 500px;
  height: 500px;
  background: radial-gradient(circle 250px, #ffffff, transparent 100%);
  top: -300px;
  left: -250px;
}

.march-main-images.light-right {
  top: -20%;
  right: -100%;
  z-index: 1;
}

.march-main-images.light-right.tablet,
.march-main-images.light-right.halftablet,
.march-main-images.light-right.mobile {
  display: none;
}

.march-main-button.tablet {
  font-weight: 600;
  font-size: 1em;
}

.march-main-button.halftablet {
  font-weight: 600;
  font-size: 0.8em;
}

.march-main-button.mobile {
  display: none;
}

.march-main-button.phone {
  display: none;
}

.march-main-button.phone.mobile {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 15;
  margin: 0 auto 1em auto;
  box-sizing: border-box;
  padding: 0.2em 1em;
  font-size: 0.9em;
  font-weight: 600;
  top: -1em;
}


.advantages-container {
  padding-top: 2em;
  margin-bottom: 3em;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  line-height: 1.2em;
}

.advantages-container.tablet {
  font-size: 0.8em;
}

.advantages-container.halftablet,
.advantages-container.mobile {
  font-size: 0.7em;
  margin-bottom: 5em;
}

.advantages-container.mobile {
  padding-top: 0;
}

.advantages-border {
  width: 100%;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(18, 56, 64, 1) 10%, rgba(18, 56, 64, 1) 90%, rgba(255, 255, 255, 0) 100%);
  top: 0;
}

.advantages-border.mobile {
  display: none;
}

.advantages {
  text-align: center;
  width: 25%;
}

.advantages.halftablet {
  width: 30%;
}

.advantages.mobile {
  width: 32%;
}

.adv-number {
  font-size: 4.5em;
  line-height: 1em;
  font-weight: 400;
  font-style: italic;
}

.adv-number.tablet {
  font-size: 3.5em;
}

.adv-number.halftablet,
.adv-number.mobile {
  font-size: 2.5em;
  margin-bottom: 0.2em;
}

.product-vk {
  background: #ffffff99;
  border-radius: 1em;
  padding: 0em 10em;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  margin-top: -2em;
  margin-bottom: 3em;
  position: relative;
  z-index: 1;
}

.product-vk.tablet {
  padding: 0em 3em;
  margin-top: 0em;
  margin-bottom: 1em;
}

.product-vk.halftablet,
.product-vk.mobile {
  padding: 0.5em 1em;
  margin-top: 2em;
  margin-bottom: 1em;
}

.product-vk.mobile {
  flex-direction: column;
  padding: 11em 1em 1em 1em;
  margin-top: 2em;
  margin-bottom: 1em;
}

.product-vk h3 {
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3em;
  line-height: 1.3em;
}

.product-vk.tablet h3,
.product-vk.halftablet h3,
.product-vk.mobile h3 {
  font-size: 1.1em;
  margin-bottom: 0.5em;
}

.product-vk.tablet p,
.product-vk.halftablet p,
.product-vk.mobile p {
  line-height: 1.3em;
}

.product-vk-img-block {
  position: relative;
  min-width: 195px;
}

.product-vk-img-block.mobile {
  position: absolute;
  top: -1em;
}

.product-vk-img {
  width: 195px;
  position: relative;
}

.who-block {
  display: flex;
  justify-content: space-between;
  margin: 5em 0 3em 0;
}

.who-block.halftablet,
.who-block.mobile {
  flex-wrap: wrap;
  flex-direction: column-reverse;
  margin-top: 2em;
}

.who-text {
  width: 45%;
}

.who-text.tablet {
  width: 70%;
  font-size: 0.9em;
}

.who-text.halftablet,
.who-text.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 0.9em;
}

.who-text.mobile h2 {
  text-align: center;
}

.who-img-block {
  width: 50%;
}

.who-img-block.halftablet,
.who-img-block.mobile {
  width: 100%;
  margin-bottom: 2em;
}

.who-block p {
  margin-bottom: 2em;
}

.buy-all, .buy {
  background-color: #ffffff99;
  border-radius: 1em;
  padding: 1.7em;
  position: relative;
  /* margin-bottom: 5em; */
}

.buy-all {
  text-align: center;
}

.buy-all-text {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: 800;
  width: 90%;
  margin: 0 auto;
  align-items: center;
}

.buy-all-text.tablet,
.buy-all-text.halftablet,
.buy-all-text.mobile {
  font-size: 1.1em;
  width: 100%;
}

.buy-all-text.halftablet,
.buy-all-text.mobile {
  flex-direction: column;
}

.buy-all-text p {
  line-height: 1em;
  text-align: center;
}

.buy-all-text.halftablet p {
  margin-bottom: 0.5em;
}

.buy-all-price {
  font-size: 2.5em;
  font-weight: 400;
  padding: 0 0.5em;
}

.buy-all-price.tablet,
.buy-all-price.halftablet,
.buy-all-price.mobile {
  font-size: 2em;
  font-weight: 600;
}

.buy-all-price .old {
  font-size: 0.7em;
  color: #274d5d77;
  font-weight: 400;
  text-decoration: line-through;
}

.buy-parts {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 1.5em 0 5em 0;
  font-size: 0.8em;
}

.buy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-content: center;
  margin-bottom: 3em;
  padding: 1.7em 3em !important;
}

.buy.tablet {
  justify-content: space-around;
}

.buy.halftablet, .buy.mobile {
  flex-wrap: wrap;
  flex-direction: column-reverse;
  margin-bottom: 3em;
}

.buy-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  z-index: 10;
}

.buy-text.halftablet,
.buy-text.mobile {
  margin: 1em 0 2em 0;
}

.buy .buy-text .old {
  color: #274d5d99;
  padding-right: 3.2rem;
}

.buy a {
  font-weight: 600;
  font-size: 0.8em;
  display: inline-block;
  line-height: 1.2em;
  text-align: center;
}

.button-buy {
  font-weight: 600;
  padding: 1.2em 2em;
  z-index: 1;
  position: relative;
}

.button-buy.mobile {
  padding: 1.2em 1.5em;
}

.button-buy.end {
  font-size: 1em;
}

.buy-all-button {
  position: relative;
  margin: 0 auto;
  display: inline-block;
  top: 2.5em;
}

.buy-button {
  position: relative;
  width: 35%;
}

.buy-button.tablet {
  width: 40%;
}

.buy-button.halftablet {
  width: 80%;
}

.buy-button.mobile {
  width: 100%;
}

.buy-button.halftablet {
  margin-bottom: 3em;
}

.buy-button.mobile {
  margin-bottom: 1em;
}

.buy-button-bg {
  width: 340px;
  height: 173px;
  position: absolute;
  top: -80%;
  left: -10%;
  z-index: 0;
}

.buy-button-bg.tablet,
.buy-button-bg.mobile {
  width: 250px;
  top: -40%;
}

.buy p {
  font-size: 3em;
  line-height: 1em;
  text-align: center;
}

.buy.tablet p,
.buy.halftablet p,
.buy.mobile p {
  font-size: 2em;
  font-weight: 600;
}

.buy p.old {
  font-size: 1.5em;
  color: #ffffff55;
  text-decoration: line-through;
  font-weight: 400;
}

.buy.tablet p.old,
.buy.halftablet p.old,
.buy.mobile p.old {
  font-size: 1.1em;
}

.buy-bee {
  width: 35px;
  position: absolute;
  right: 3%;
  top: 10%;
}

.buy-bee.tablet {
  left: -20px;
}

.buy-bee.halftablet {
  left: 1em;
  top: 50%;
}

.buy-bee.mobile {
  left: -0.7em;
  top: 50%;
}

.buy-petals {
  position: absolute;
  width: 360px;
  z-index: 0;
  left: 10%;
}

.buy-petals.tablet {
  width: 250px;
  left: 0;
}

.buy-petals.halftablet,
.buy-petals.mobile {
  display: none;
}

.reviews {
  position: relative;
  margin-bottom: 2em;
}

.reviews.tablet,
.reviews.halftablet,
.reviews.mobile {
  margin-bottom: 3em;
}

.reviews.mobile {
  margin-bottom: 3em !important;
}

.review-dark {
  width: 150px;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  pointer-events: none;
}

.reviews.tablet .review-dark,
.reviews.halftablet .review-dark {
  width: 100px;
}

.reviews.mobile .review-dark {
  width: 50px;
}

.review-dark.left {
  background: linear-gradient(270deg, rgba(211, 233, 227, 0) 0%, rgba(211, 233, 227, 1) 100%);
  left: 0;
}

.review-dark.right {
  background: linear-gradient(90deg, rgba(211, 233, 227, 0) 0%, rgba(211, 233, 227, 1) 100%);
  right: 0;
}

.add {
  margin: 5em 0 0 0;
}

.add.halftablet {
  margin: 3em 0 0 0;
  font-size: 0.8em;
}

:deep(.BFitem-h), :deep(.BFitem-h span) {
  color: #274d5d !important;
}

:deep(.container) {
  padding: 0;
}

:deep(.BFitem-text.halftablet p, .BFitem-text.mobiles p) {
  font-size: 1em;
}

:deep(.BFcont-block) {
  width: 45%;
  margin-bottom: 2em !important;
}

:deep(.BFcont-block.halftablet) {
  width: 100%;
  margin-bottom: 2em !important;
}

:deep(.BFcont-block.mobile) {
  width: 100%;
  margin-bottom: 2em !important;
}

:deep(.BFitem-top) {
  margin-bottom: 1em;
}

:deep(.BFitem-price) {
  justify-content: space-between;
  margin-top: 0;
}

:deep(.BFitem-price .pnew) {
  font-size: 1.8em;
}

:deep(.BFbuy-but) {
  width: auto;
  background-color: #d76666 !important;;
  box-shadow: none !important;
  font-size: 0.9em;
  font-weight: 600;
}

.faq {
  font-size: 0.9em;
}

</style>
<style>
.accordion__panel button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.accordion__panel .accordion__panel-header-toggle {
  width: 100%;
  display: block;
  text-align: left;
  border: 0px solid transparent;
  border-radius: 0.5em;
  background: #ffffff99;
  color: #274d5d;
  padding: 0.8em 1.5em;
  margin-bottom: 0.5em;
}

.accordion__panel-content {
  font-size: 0.9em;
  margin: 0 2em 1.5em 2em;
  animation-name: open;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}

@keyframes open {
  0% {
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
</style>